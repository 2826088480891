export default [
  // {
  //   path: '/dashboard/analytics',
  //   name: 'dashboard-analytics',
  //   component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  // },
  // {
  //   path: '/shop/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  // },
]
