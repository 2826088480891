export default [
  {
    path: "/shop/login",
    name: "shopauth-login",
    component: () => import("@/views/pages/authentication/Login-v1.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/dashboard/analytics",
    name: "dashboard-analytics",
    component: () => import("@/views/dashboard/analytics/Analytics.vue"),
  },
  {
    path: "/shop/dashboard/ecommerce",
    name: "dashboard-ecommerce",
    component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue"),
  },
  {
    path: "/shop/open-bill",
    name: "open-bill",
    component: () =>
      import("@/views/open-bill/e-commerce-checkout/ECommerceCheckout.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/shop/confirm-order",
    name: "confirm-order",
    component: () =>
      import("@/views/confirm-order/e-commerce-checkout/ECommerceCheckout.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/shop/invoice/preview/:id",
    name: "apps-invoice-preview",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoicePreview.vue"),
  },
  // {
  //   path: "/shop/history-order",
  //   name: "history-order",
  //   component: () =>
  //     import("@/views/history-order/invoice-preview/InvoicePreview.vue"),
  //   meta: {
  //     contentClass: "ecommerce-application",
  //   },
  // },

  {
    path: "/shop/history-order",
    name: "history-order",
    component: () =>
      import("@/views/history-order/invoice-list/InvoiceList.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/shop/history-order/preview/:id",
    name: "history-orderpreview",
    component: () =>
      import("@/views/history-order/invoice-preview/InvoicePreview.vue"),
  },

  {
    path: "/shop/my-product",
    name: "my-products",
    component: () => import("@/views/my-products/MyProducts.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },

  {
    path: "/customer/my-customer",
    name: "my-customer-today",
    component: () => import("@/views/my-customer/MyCustomer.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },

  {
    path: "/shop/logout",
    name: "shop-logout",
    component: () => import("@/views/logout/MyProducts.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },

  // ADMIN
  {
    path: "/admin/login",
    name: "adminauth-login",
    component: () => import("@/views/pages/authentication/Login-v1admin.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/dashboardadmin/analytics",
    name: "dashboardadmin-analytics",
    component: () => import("@/views/dashboardadmin/analytics/Analytics.vue"),
  },
  {
    path: "/admin/dashboardadmin/ecommerce",
    name: "dashboardadmin-ecommerce",
    component: () => import("@/views/dashboardadmin/ecommerce/Ecommerce.vue"),
  },
  {
    path: "/admin/dashboard",
    name: "admin-dashboard",
    component: () => import("@/views/dashboardadmin/ecommerce/EcommerceDashboard.vue"),
  },
  {
    path: "/admin/order-list",
    name: "admin-order-list-no-print",
    component: () => import("@/views/order-list/invoice-list/InvoiceList.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },

  {
    path: "/admin/wait-order-list",
    name: "admin-wait-order-list",
    component: () => import("@/views/order-list/invoice-list/WaitInvoiceList.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  
  {
    path: "/admin/printed-order-list",
    name: "admin-printed-order-list",
    component: () => import("@/views/order-list/invoice-list/InvoiceListPrinted.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/order-list/preview/:id",
    name: "admin-orderpreview",
    component: () =>
      import("@/views/order-list/invoice-preview/InvoicePreview.vue"),
  },
  {
    path: "/admin/openbill-list",
    name: "admin-openbill-list",
    component: () =>
      import("@/views/openbill-list/invoice-list/InvoiceList.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/openbill/preview/:id",
    name: "admin-openbillpreview",
    component: () =>
      import("@/views/openbill-list/invoice-preview/InvoicePreview.vue"),
  },
  {
    path: "/admin/openbill",
    name: "admin-openbill",
    component: () => import("@/views/order-openbill/InvoiceAdd.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },

  {
    path: "/admin/products/list",
    name: "admin-products-list",
    component: () => import("@/views/products-list/ProductList.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/products/create",
    name: "admin-products-create",
    component: () => import("@/views/products-list/Product.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/products/edit/:id",
    name: "admin-products-edit",
    component: () => import("@/views/products-list/Product.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/warehouses/list",
    name: "admin-warehouse-list",
    component: () => import("@/views/warehouses-list/ProductList.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/warehouses/create",
    name: "admin-warehouse-create",
    component: () => import("@/views/warehouses-list/Product.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/warehouses/edit/:id",
    name: "admin-warehouse-edit",
    component: () => import("@/views/warehouses-list/Product.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/members/list",
    name: "admin-members-list",
    component: () => import("@/views/members-list/ProductList.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/members/create",
    name: "admin-members-create",
    component: () => import("@/views/members-list/Product.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/members/edit/:id/:roleId",
    name: "admin-members-edit",
    component: () => import("@/views/members-list/Product.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },

  {
    path: "/admin/customer/list",
    name: "admin-customer-list",
    component: () => import("@/views/customer-list/CustomerList.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/admin/customer/order/:order",
    name: "admin-customer-order",
    component: () => import("@/views/customer-list/OrderList.vue"),
    meta: {
      contentClass: "ecommerce-application",
    },
  },
];
